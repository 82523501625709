import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

class CodeEffentiRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (

        <div className="columns is-multiline">
            {posts &&
              posts.map(({ node: post }) => (
                <div className="is-parent column is-6" key={post.id}>
                  <article className="tile is-child box">
                    <p>
                      <Link
                        className="title has-text-primary is-size-4"
                        to={post.fields.slug}
                      >
                        {post.frontmatter.title}
                      </Link>
                        <br/>
                      <span className="subtitle is-block">
                        {post.frontmatter.date}
                      </span>
                    </p>
                    <p>
                      {post.excerpt}
                      <br />
                      <br />
                      <Link className="btn" to={post.fields.slug}>
                        Voir plus
                      </Link>
                    </p>
                  </article>
                </div>
              ))}
        </div>

    )
  }
}

CodeEffentiRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query CodeEffentiRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "code-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "D MMMM YYYY", locale: "fr" )
              }
            }
          }
        }
      }
    `}
    render = {
        (data, count) => <CodeEffentiRoll data = {
          data
        }
        count = {
          count
        }
        />}
  />
)

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import CodeEffentiRoll from '../components/CodeEffentiRoll'

export const CodeEffentiTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div className="wrapper service-bg">
        <div className="container">
          <section className="main-ctn code-effenti">

            <h1 className="main title">
              {title}
            </h1>
            <PageContent className="content" content={content} />

            <CodeEffentiRoll />
          </section>
        </div>
        <div className="spacer-red triangle-red-left is-relative"></div>
    </div>
  )
}

CodeEffentiTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const CodeEffenti = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <CodeEffentiTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

CodeEffenti.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CodeEffenti

export const codeEffentiQuery = graphql`
  query CodeEffenti($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
